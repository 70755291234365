export const getCountryList = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/geo/country`, {
      method: 'GET'
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching countries:', error);
    throw error;
  }
};

export const getStateList = async (countryId: number) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/geo/state/${countryId}`, {
      method: 'GET'
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching states:', error);
    throw error;
  }
};

export const getCityList = async (stateId: number) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/geo/city/${stateId}`, {
      method: 'GET'
    });
    return await response.json();
  } catch (error) {
    console.error('Error fetching cities:', error);
    throw error;
  }
};