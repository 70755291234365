import { ReductionInitiativesStatus } from './types';

export const TOTAL_EMISSIONS_VALUE = 500

export const totalScopeEmissionsRecaptureData = {
  current: 33,
  '2030': 60, 
  '2040': 90,
  '2050': 90
} // Total sum = 74.7 (18.7 + 12 + 19 + 25)

export const totalScopeEmissionsDonutData = [
  {name: 'Scope 1', count: 25},
  {name: 'Scope 2', count: 200}, 
  {name: 'Scope 3', count: 275}
] // Total sum = 500 (25 + 200 + 275)

export const projected2030EmissionsDonutData = [
  {name: 'Scope 1', count: 20},
  {name: 'Scope 2', count: 114}, 
  {name: 'Scope 3', count: 220}
] // Total sum = 354 (20 + 114 + 220)

export const projected2040EmissionsDonutData = [
  {name: 'Scope 1', count: 20},
  {name: 'Scope 2', count: 50},
  {name: 'Scope 3', count: 220}
] // Total sum = 290 (20 + 50 + 220)

export const projected2050EmissionsDonutData = [
  {name: 'Scope 1', count: 20},
  {name: 'Scope 2', count: 28},
  {name: 'Scope 3', count: 220}
] // Total sum = 268 (20 + 28 + 220)

export const topCategoriesEmissionData = [
  {emission: 200, percentage_contribution: 42.5, title: 'Mobile Combustion'},
  {emission: 150, percentage_contribution: 31.9, title: 'Transportation'},
  {emission: 120, percentage_contribution: 25.6, title: 'Purchased Electricity'}
]

export const locationEmissionData = [
  {location: 'Bangalore (India)', emissions: 200, totalEmployees: 80, emissionsPerEmployee: 2.5},
  {location: 'Mumbai (India)', emissions: 100, totalEmployees: 40, emissionsPerEmployee: 2.5},
  {location: 'Delhi (India)', emissions: 50, totalEmployees: 20, emissionsPerEmployee: 2.5},
  {location: 'New York (USA)', emissions: 100, totalEmployees: 40, emissionsPerEmployee: 2.5},
  {location: 'San Francisco (USA)', emissions: 50, totalEmployees: 20, emissionsPerEmployee: 2.5}
]

export const reductionInitiativesData = [
  {
    initiative: 'Switch to Electric Fleet for Company-Owned Vehicles (Scope 1)',
    targetReduction: 7.5,
    targetDate: '2025',
    lead: 'Operations Manager',
    status: ReductionInitiativesStatus.IN_PROGRESS
  },
  {
    initiative: 'Procurement of Renewable Energy Certificates (Scope 2)', 
    targetReduction: 100,
    targetDate: '2030',
    lead: 'Sustainability Officer',
    status: ReductionInitiativesStatus.IN_PROGRESS
  },
  {
    initiative: 'Expand Telecommuting and Virtual Meeting Policies (Scope 3)',
    targetReduction: 25,
    targetDate: '2025', 
    lead: 'HR / IT Collaboration Lead',
    status: ReductionInitiativesStatus.COMPLETED
  }
]

export const scopeTwoLocationEmissionData = [
  {location: 'Bangalore (India)', emissions: 64, totalEmployees: 80, emissionsPerEmployee: 0.8},
  {location: 'Mumbai (India)', emissions: 32, totalEmployees: 40, emissionsPerEmployee: 0.8}, 
  {location: 'Delhi (India)', emissions: 16, totalEmployees: 20, emissionsPerEmployee: 0.8},
  {location: 'New York (USA)', emissions: 60, totalEmployees: 40, emissionsPerEmployee: 1.5},
  {location: 'San Francisco (USA)', emissions: 28, totalEmployees: 20, emissionsPerEmployee: 1.4}
]

export const scopeOneCategoryData = [
  {
    operationalBoundary: 'Direct Emissions',
    data: [
      {emissionSource: 'Fuel Consumption In Company-Owned Vehicles', emissions: 15},
      {emissionSource: 'Diesel Consumption In DG Sets', emissions: 8},
      {emissionSource: 'Fugitive Emissions', emissions: 2}
    ],
    totalEmissions: 25
  }
]

export const scopeTwoCategoryData = [
  {
    operationalBoundary: "Indirect Emissions",
    emissionsSource: "Purchased Electricity (For Data Centers And Offices)",
    emissions: 1,
    totalEmissions: 200
  }
]
