import clsx from 'clsx'
import {FC, useEffect, useMemo} from 'react'
import {useTable, useSortBy, usePagination, HeaderGroup} from 'react-table'
import {useIntl} from 'react-intl'
import NoResultsMessage from '../../../../app/modules/employee/NoResultsMessage'
import Loader from '../loader/Loader'
import {ReactTableProps} from '../types'

const ReactTablePurple: FC<ReactTableProps> = (props) => {
  const intl = useIntl()
  const {
    headerData,
    tableData,
    className,
    manualSort,
    disableSort,
    pagesCount,
    onPageChange,
    showPagination = true,
    currentPageIndex,
    onSort,
    manualPagination,
    pageSize = 10,
    isLoading = false,
    message = intl.formatMessage({id: 'NO_RESULTS_FOUND'}),
  } = props
  const columns = useMemo(() => headerData, [headerData])
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: {pageIndex, sortBy},
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: {
        pageIndex: currentPageIndex,
        pageSize,
        sortBy: [
          {
            id: 'name', 
            desc: false,
          }
        ]
      },
      ...(manualPagination && {manualPagination, pageCount: pagesCount}),
      manualSortBy: manualSort,
      disableSortBy: disableSort,
      disableMultiSort: true,
    },
    useSortBy,
    usePagination
  )

  useEffect(() => {
    setPageSize(pageSize)
  }, [pageSize])

  useEffect(() => {
    if (onSort) {
      if (sortBy?.length) {
        const sortOrder = sortBy[0].desc ? 'desc' : 'asc'
        onSort(sortBy[0].id, sortOrder)
      } else {
        onSort('', '')
      }
    }
  }, [sortBy])

  useEffect(() => {
    if (onPageChange) onPageChange(pageIndex)
  }, [pageIndex])

  useEffect(() => gotoPage(currentPageIndex), [currentPageIndex])

  const getHeaderStyles = (column: HeaderGroup<object>) => {
    if (column.isSorted) {
      const order = column.isSortedDesc ? 'desc' : 'asc'
      return clsx(className, column.isSorted ? `table-sort-${order}` : '')
    }
    return className
  }

  const pageRange = []
  const currentPage = pageIndex + 1
  for (let i = currentPage - 1; i <= currentPage + 1; i += 1) {
    if (i >= 1 && i <= pageCount) {
      pageRange.push(i)
    }
  }
  if (pageRange.length === 2 && pageCount >= 3) {
    if (pageRange[0] === 1) {
      pageRange.push(pageRange[1] + 1)
    } else {
      pageRange.unshift(pageRange[0] - 1)
    }
  }

  return (
    <div className={`card ${className} p-0 m-0`}>
      {/* begin::Body */}
      <div className='card-body p-0 m-0' style={{borderRadius: '13px', overflow: 'hidden'}}>
        {/* begin::Table container */}
        <div className='table-responsive p-0 m-0'>
          {isLoading ? (
            <Loader />
          ) : (
            <table {...getTableProps()} className='table table-row-dashed align-middle gs-0 gy-4 p-0 m-0'>
              <thead style={{backgroundColor: '#D7D3E4', margin: '0px'}}>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={Math.random()}
                    className='text-start text-gray-600 fw-bolder fs-6 gs-0'
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                        key={Math.random()}
                        className={`${getHeaderStyles(column)} py-5`}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              {tableData.length === 0 ? (
                <tbody {...getTableBodyProps()} className='text-gray-600 fw-bold fs-4'>
                  <NoResultsMessage message={message} />
                </tbody>
              ) : (
                <tbody {...getTableBodyProps()} className='text-black-500 fw-bold'>
                  {page.map((row, index) => {
                    prepareRow(row)
                    return (
                      <tr {...row.getRowProps()} key={Math.random()} style={{backgroundColor: index % 2 === 0 ? '#FAFAFC' : '#FFFFFF'}}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()} key={Math.random()}>
                            <div className='d-block fs-6'>{cell.render('Cell')}</div>
                          </td>
                        ))}
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </table>
          )}
        </div>
        {/** pagination starts */}
        {showPagination && (
          <div className='row p-0 m-0'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start' />
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div id='kt_table_users_paginate'>
                <ul className='pagination'>
                  <li
                    className={clsx('page-item', {
                      active: false,
                      disabled: !canPreviousPage,
                    })}
                  >
                    <button
                      type='button'
                      className={clsx('page-link', {
                        'page-text': true,
                        'me-5': true,
                      })}
                      onClick={previousPage}
                      style={{cursor: 'pointer'}}
                    >
                      Previous
                    </button>
                  </li>

                  {pageRange.map((pageOption) => (
                    <li
                      key={pageOption}
                      className={clsx('page-item', {
                        active: pageIndex === pageOption - 1,
                      })}
                    >
                      <button
                        type='button'
                        className={clsx('page-link', {
                          'page-text': false,
                          'me-5': false,
                        })}
                        onClick={() => gotoPage(pageOption - 1)}
                        style={{cursor: 'pointer'}}
                      >
                        {pageOption}
                      </button>
                    </li>
                  ))}
                  <li
                    className={clsx('page-item', {
                      active: false,
                      disabled: !canNextPage,
                    })}
                  >
                    <button
                      type='button'
                      className={clsx('page-link', {
                        'page-text': true,
                        'me-5': false,
                      })}
                      onClick={nextPage}
                      style={{cursor: 'pointer'}}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        {/** paginations ends */}
      </div>
    </div>
  )
}

export default ReactTablePurple
