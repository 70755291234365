import { useEffect, useState } from 'react'
import moment from 'moment'
import {useSelector, useDispatch} from 'react-redux'
import {useLocation} from 'react-router-dom'
import {Navbar, Nav, NavDropdown, Image} from 'react-bootstrap'

import {RootState} from '../../../../app/store/store'
import {getLocalStorageItem} from '../../../../app/utils/storage'
import { getMonthsArrayFromYear, getYearsArrayByLiveOnData, getDateRange } from '../../../../app/utils/helpers'

import {
  updateMonthFilter, 
  updateDivSubCateogryFilter, 
  updateDeptSubCateogryFilter, 
  updateLocSubCateogryFilter, 
  updateCategoryFilter,
  updateYearFilter,
  updateDateFilter,
  udpateLeaderboardTimePeriodFilter, 
} from '../../../../app/store/slices/filters'

// type MonthSelectCallback = (month: string) => void

type SubCategoryNavBarProps = {
  location: any
}

type TimePeriodNavDropdownProps = {
  locaton: any
}

type YearNavDropdownType = {
  filterYear: string, 
  yearOptions: string[],
}

type MonthNavDropdownType = {
  filterMonth: string, 
  monthOptions: string[]
}

// interface HeaderToolbarProps {
//   onMonthSelect: MonthSelectCallback
// }

function CategoryNavDropdown () {
  const dropDownList = ['Department', 'Division', 'Location']

  const dispatch = useDispatch();
  const { filterCategory } = useSelector((state: RootState) => state.filters)

  const handleCategorySelect = (category:any) => {
    dispatch(updateCategoryFilter(category))
  }

  return (
    <Nav className='mr-auto align-items-center nav-month-selector'>
      {/* <h4 className='text-light ml-4 mb-0'>Category:</h4> */}
      <NavDropdown
        className='zIndex200 custom-header-dropdown'
        style={{minWidth: '140px'}}
        title={filterCategory}
        id='basic-nav-dropdown'
        >
        {dropDownList.map((category:string) => (
          <NavDropdown.Item 
            key={category} 
            title={category} 
            onClick={() => handleCategorySelect(category)}
            style={{
              backgroundColor: (category === filterCategory) ? '#e6e6e6' : 'white',
              fontWeight: (category === filterCategory) ? '800' : '400',
            }}
            >
            {category}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
      {/* <Button type="button" className="btn-custom-date btn-outline-primary">+ Custom Date</Button> */}
    </Nav>
  )
}

function SubCategoryNavDropdown ({location}: SubCategoryNavBarProps) {
  const path = location.pathname
  const {
    filterDivSubCategory, 
    filterDeptSubCategory, 
    filterLocSubCategory
  } = useSelector((state: RootState) => state.filters)
  const dispatch = useDispatch()

  const [dropDownLabel, setDropDownLabel] = useState<string | null>(null)
  const [dropDownList, setDropDownList] = useState<any>([])
  const {departmentsList: deptList, divisionsList: divList, locationsList: locList} = useSelector((state: any) => state.masterList)
  const departmentsList = deptList.map((item: any) => item.name)
  const divisionsList = divList.map((item: any) => item.name)
  const locationsList = locList.map((item: any) => item.name)

  function checkDropDownLabel(){
    if (path.match('/dashboard-emission/divisions')) {
      setDropDownLabel('Division')
      return null;
    }
    else if (path.match('/dashboard-emission/departments')) {
      setDropDownLabel('Department')
      return null;
    } 
    else if (path.match('/dashboard-emission/locations')) {
      setDropDownLabel('Location')
      return null;
    }
    else {
      setDropDownLabel(null)
      return null
    } 
  }
  useEffect(() => {
    checkDropDownLabel();
  }, [location])

  useEffect(() => {
    if (divisionsList.length>0 && path.match('/dashboard-emission/divisions')) {
      setDropDownList(['All', ...divisionsList])
    }
    else if (departmentsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      setDropDownList(['All', ...departmentsList])
    }
    else if (locationsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      setDropDownList(['All', ...locationsList])
    }
  }, [departmentsList, divisionsList, locationsList, location])

  const handleSubCategorySelect = (subCategory: string) => {
    if (divisionsList.length>0 && path.match('/dashboard-emission/divisions')) {
      dispatch(updateDivSubCateogryFilter(subCategory))
    }
    else if (departmentsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      dispatch(updateDeptSubCateogryFilter(subCategory))
    }
    else if (locationsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      dispatch(updateLocSubCateogryFilter(subCategory))
    }
  }

  const getSubCategoryTitle = () => {
    if (divisionsList.length>0 && path.match('/dashboard-emission/divisions')) {
      return filterDivSubCategory
    }
    else if (departmentsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      return filterDeptSubCategory
    }
    else if (locationsList.length>0 && path.match('/dashboard-emission/divisionss')) {
      return filterLocSubCategory
    }
    return 'Null'
  }
  
  return (<Nav className='mr-auto align-items-center nav-month-selector'>
        <h4 className='text-light ml-4 mb-0'>{dropDownLabel}:</h4>
        <NavDropdown
          className='zIndex200 custom-header-dropdown'
          style={{minWidth: '90px'}}
          title={getSubCategoryTitle()}
          id='basic-nav-dropdown'
        >
          {dropDownList.map((subCategory:string) => (
            <NavDropdown.Item 
              key={subCategory} 
              title={subCategory} 
              onClick={() => handleSubCategorySelect(subCategory)}
              >
              {subCategory}
            </NavDropdown.Item>
          ))}
        </NavDropdown>
      </Nav>)
}

function TimePeriodNavDropdown () {
  type timePeriodLabelMapType = {
    [key: string]: string,
  }

  const now = moment().utc();
  
  // if monday then curr_week = 0 days
  const dropDownList: string[] = now.day() === 1 ? [
      "daily", "weekly", "curr_month", "monthly", "6_months", "curr_year", "yearly"
    ] : [
      "daily", "curr_week", "weekly", "curr_month", "monthly", "6_months", "curr_year", "yearly"
    ];

  const timePeriodLabelMap: timePeriodLabelMapType = {
    "daily": "Yesterday",
    "curr_week": "Current Week",
    "weekly": "Last Week",
    "curr_month": "Current Month",
    "monthly": "Last Month",
    "6_months": "Last 6 Months",
    "curr_year": "Current Year",
    "yearly": "Last Year"
  };

  const dispatch = useDispatch();
  const { filterLeaderboardTimePeriod } = useSelector((state: RootState) => state.filters)

  const handleCategorySelect = (category:string) => {
    dispatch(udpateLeaderboardTimePeriodFilter(category));
  }

  return (
    <Nav className='mr-auto align-items-center nav-month-selector'>
      <h4 className='text-light ml-4 mb-0'>Period:</h4>
      <NavDropdown
        className='zIndex200 custom-header-dropdown'
        style={{minWidth: '140px'}}
        title={timePeriodLabelMap[filterLeaderboardTimePeriod]}
        id='basic-nav-dropdown'
        >
        {dropDownList.map((category:string) => (
          <NavDropdown.Item 
            key={category} 
            title={category} 
            onClick={() => handleCategorySelect(category)}
            style={{
              backgroundColor: (category === filterLeaderboardTimePeriod) ? '#e6e6e6' : 'white',
              fontWeight: (category === filterLeaderboardTimePeriod) ? '800' : '400',
            }}
            >
            {timePeriodLabelMap[category]}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  )
}

function YearNavDropdown ({filterYear, yearOptions}: YearNavDropdownType) {
  const dispatch = useDispatch()

  const handleYearSelect = (year: string) => {
    dispatch(updateYearFilter(year))
  }

  return (
    <Nav className='mr-auto align-items-center nav-month-selector'>
      <h4 className='text-light ml-4 mb-0'>Year:</h4>
      <NavDropdown
        className='zIndex200 custom-header-dropdown'
        style={{minWidth: '90px'}}
        title={filterYear || yearOptions[0]}
        id='basic-nav-dropdown'
      >
        {yearOptions.map((year) => (
          <NavDropdown.Item 
            key={year} 
            title={year} 
            onClick={() => handleYearSelect(year)}
            style={{
              backgroundColor: (filterYear === year) ? '#e6e6e6' : 'white',
              fontWeight: (filterYear === year) ? '800' : '400',
            }}
            >
            {year}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  )
}

function MonthNavDropdown ({filterMonth, monthOptions}: MonthNavDropdownType) {
  const dispatch = useDispatch()

  const handleMonthSelect = (month: string) => {
    dispatch(updateMonthFilter(month))
  }

  return (
    <Nav className='mr-auto align-items-center nav-month-selector'>
      <h4 className='text-light ml-4 mb-0'>Month:</h4>
      <NavDropdown
        className='zIndex200 custom-header-dropdown'
        style={{minWidth: '90px'}}
        title={filterMonth || monthOptions[0]}
        id='basic-nav-dropdown'
      >
        {monthOptions.map((month) => (
          <NavDropdown.Item 
            key={month} 
            title={month} 
            onClick={() => handleMonthSelect(month)}
            style={{
              backgroundColor: (filterMonth === month) ? '#e6e6e6' : 'white',
              fontWeight: (filterMonth === month) ? '800' : '400',
            }}
            >
            {month}
          </NavDropdown.Item>
        ))}
      </NavDropdown>
    </Nav>
  )
}

function HeaderToolbar() {
  const location = useLocation()

  const dispatch = useDispatch()

  const userDetails = useSelector((state: RootState) => state.profile.profile)
  const {filterDate, filterMonth, filterYear} = useSelector((state: RootState) => state.filters)


  const liveOnDate = new Date(getLocalStorageItem('live-on')) // Convert to Date object
  const currentDate = new Date()
  currentDate.setDate(currentDate.getUTCDate() - 1)
  // const currentMonth = currentDate.getUTCMonth() + 1 // Get the current month (1-12)

  const monthOptions = getMonthsArrayFromYear(liveOnDate, filterYear, userDetails?.organization?.isWorkshopOrg).concat('All').reverse()
  const yearOptions = getYearsArrayByLiveOnData(liveOnDate).reverse()

  // WORKSHOP
  if (userDetails?.organization?.isWorkshopOrg) {
    yearOptions.reverse().pop()
    yearOptions.push('2030', 'All')
    yearOptions.reverse()
  }

  useEffect(() => {
    if (liveOnDate && userDetails) {
      const dateRange = getDateRange(filterYear, filterMonth, liveOnDate, userDetails?.organization?.isWorkshopOrg); 
      dispatch(updateDateFilter(dateRange))
    }
  }, [filterMonth, filterYear, userDetails])

  // useEffect(() => {
  //   console.log('date range.. ', filterDate)
  // }, [filterDate])

  // const isPathInCategory = () => {
  //   if (location.pathname === '/dashboard-emission/divisions' ||
  //       location.pathname === '/dashboard-emission/divisionss' ||
  //       location.pathname === '/dashboard-emission/divisionss') {
  //         return true;
  //       }
  //     return false;
  // }

  return (
    <Navbar className='navbar navbar-expand-lg navbar-dark bg-dark h-100'>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav' className='justify-content-between px-6'>
        <div className='d-flex'>        
          {
            (['/details'].includes(location.pathname)) && 
            <CategoryNavDropdown />
          }
          {
            (['/leaderboard'].includes(location.pathname)) && 
            <TimePeriodNavDropdown />
          }
          {
            (!['/leaderboard'].includes(location.pathname)) &&
            <YearNavDropdown filterYear={filterYear} yearOptions={yearOptions} />
          }
          {
            (!['/leaderboard'].includes(location.pathname) && filterYear !== 'All' && !(userDetails?.organization?.isWorkshopOrg && filterYear === '2030')) &&
            <MonthNavDropdown filterMonth={filterMonth} monthOptions={monthOptions} />
          }
          {/* {
            isPathInCategory() &&
            <SubCategoryNavDropdown location={location} />
          } */}
        </div>
        <Nav className='ml-auto d-flex flex-row align-items-center'>
          {/* <Nav.Link href="#notifications"><i className="fas fa-bell" /></Nav.Link> */}
          <Image
            src={userDetails?.organization?.logo}
            roundedCircle
            width={40}
            height={40}
            className='ml-5'
          />
          <div className='d-flex flex-column justify-content-center mx-2 '>
            <span className='nav-link-s text-white'>{userDetails?.name}</span>
            <span className='nav-link-sa text-white'>Admin</span>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export {HeaderToolbar}
