import { useNavigate } from "react-router-dom"
import InfoHelperIcon from "../../../../_metronic/partials/components/chart/InfoHelperIcon"
import { NetZeroStatusChart } from "./net-zero-status-chart"
import { RoutesPath } from "../../../routing/RoutesPath"

export const NetZeroStatusCard: React.FC<any> = ({label, isAvailable, borderClass}) => {
  const navigate = useNavigate()

  return (
    <div className={`custom-header-card2 ${borderClass} pb-0 pt-2`}>
      <div className='chc-title2 my-0 d-flex justify-content-end'>
        {
          label === 'NET ZERO STATUS' && 
          <InfoHelperIcon 
            style={{left: '-100px'}}
            content={
              <>
                <p className='m-0'>Net zero status is the comparison of daily emissions to daily recapture from your Forest.</p>
                <p className='m-0'><span style={{fontWeight: '600'}}>&lt; 100%</span> - If the recapture is less than the emissions</p>
                <p className='m-0'><span style={{fontWeight: '600'}}>100%</span> - If the recapture is equal to the emissions</p> 
                <p className='m-0'><span style={{fontWeight: '600'}}>&gt; 100%</span> - If the recapture is greater than the emissions</p>
              </>
            } 
            />
        }
      </div>
      {
        isAvailable ? 
        <NetZeroStatusChart /> : 
        <div>
          <p style={{color: '#666666', fontSize: '13px', marginTop: '5px'}}>
            No Active Plans
          </p>
          <button
            type='button'
            className='custom-primary-delete-button m-0 mb-2 py-1 px-2'
            onClick={()=> navigate(RoutesPath.OUR_FOREST.ROOT)}
            >
            Explore Plans
          </button>
        </div>
      }
    </div>
  )
}