import {createSlice} from '@reduxjs/toolkit'
import {ProfileData} from '../../../_metronic/layout/types'

export type ProfileStoreType = {
  profile: ProfileData | null
}

const initialState: ProfileStoreType = {
  profile: null,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      // logout 
      if (action.payload === null) {
        state.profile = null
        return;
      }
      // update profile
      if (action.payload?.email?.endsWith('@mynzocarbon.com')) {
        state.profile = {...action.payload, isMynzoCarbonOrg: true}
      } else {
        state.profile = {...action.payload, isMynzoCarbonOrg: false}
      }
    },
  },
})

export const {updateProfile} = profileSlice.actions

export default profileSlice.reducer
