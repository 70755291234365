import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { IconFillArrowRightCircle, IconFillArrowLeftCircle } from '../../../../../_metronic/assets/icons';

const ForestBoundaryCarousel = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel 
      activeIndex={index} 
      onSelect={handleSelect} 
      interval={3000} 
      style={{borderRadius: 10, height: '100%', width: '100%', position: 'relative', backgroundImage: 'linear-gradient(to bottom, #FFFFFF, #F5F5F5)'}}
      prevIcon={<IconFillArrowLeftCircle height={32} width={32} />}
      nextIcon={<IconFillArrowRightCircle height={32} width={32} />}
    >
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_1.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_2.png"
          alt="Second slide"
          style={{objectFit: 'contain', height: '110%'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_3.png"
          alt="Third slide"
          style={{objectFit: 'contain', height: '110%'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_4.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%'}}
        />
      </Carousel.Item>
      <Carousel.Item style={{height: '100%'}}>
        <img
          className="d-block"
          src="https://mynzo.s3.ap-south-1.amazonaws.com/forest_lulc/year_5.png"
          alt="First slide"
          style={{objectFit: 'fill', height: '110%'}}
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default ForestBoundaryCarousel;

