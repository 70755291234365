import LocationEmissionTable from './components/location-emission-table'
import { locationEmissionData, scopeTwoCategoryData, scopeTwoLocationEmissionData } from './docs-data'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ScopeTwoEmissionTable from './components/scope-two-emission-table'
import { downloadScopeTwoReportCSV } from '../../utils/helpers'

const ScopeTwoPage = () => (
  <div className="blank-page-view pt-6 pb-16 px-6">
    <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
      <h1 className='m-0' style={{fontWeight: 600}}>SCOPE 2</h1>
      <button
        type='button'
        className='custom-primary-delete-button m-0 d-flex align-items-center'
        style={{padding: '8px 30px', fontWeight: 600}}
        onClick={()=> downloadScopeTwoReportCSV({categoryData: scopeTwoCategoryData, locationData: locationEmissionData})}
        >
        <p className='mb-0 mx-2'>DOWNLOAD REPORT</p> <DownloadIcon height={18} width={18} />
      </button>
    </div>

    <div className='mt-4'>
      <ScopeTwoEmissionTable listData={scopeTwoCategoryData} />
    </div>

    <div className='mt-12'>
      <h3 className='mt-0 mb-4'>LOCATION</h3>
      <LocationEmissionTable listData={scopeTwoLocationEmissionData} />
    </div>
  </div>
)

export default ScopeTwoPage
