/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef} from 'react'
import {useNavigate} from 'react-router-dom'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { RoutesPath } from '../../../../routing/RoutesPath'
import OpenIcon from '../../../../../assets/OpenIcon.svg'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

function getChartOptions(_height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 280,
      width: '100%',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: ["solid", "gradient"],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 1,
        stops: [30, 100, 100]
      }
    },
    // outline for each plot. Dark purple color
    // first values for top plot

    stroke: {
      curve: 'smooth',
      show: true,
      width: [2, 2],
      colors: ['#666666', '#B5DE97'],
      dashArray: [8, 0],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 3,
      title: {
        text: 'Acres',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => `${val?.toFixed(1)} Acres`,
      },
    },
    // plot fill-in color
    colors: ['#A9A8C9', '#B5DE97'],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

type ForestsPlantationChartType = {
  series: any
  categories: string[]
  disableAnimation?: boolean
}

const ForestsPlantationChart: FC<ForestsPlantationChartType> = (props) => {
  const {series, categories, disableAnimation} = props

  const navigate = useNavigate();

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const refreshMode = useCallback(() => {
    if (!chartRef.current || !series) {
      return undefined
    }

    const height = parseInt(getCSS(chartRef.current, 'height'), 10)
    const labelColor = getCSSVariableValue('--bs-gray-500')
    const values = getChartOptions(height)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${Number(value)?.toFixed(2)}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }
    return chart
  }, [series, categories, disableAnimation])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='card my-0 px-4 mt-2 self-align-center' style={{marginLeft: '0px'}}>

      {/* begin::Body */}
      <div className='card-body-chart'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ForestsPlantationChart}
