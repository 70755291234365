/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import {useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Breadcrumb} from 'react-bootstrap'
import mixpanel from 'mixpanel-browser'

import {useNavigate, useParams} from 'react-router-dom'
import ReactTable from '../../../_metronic/partials/components/tables/ReactTable'
import {RoutesPath} from '../../routing/RoutesPath'
import { useGetAllocationsQuery, useAllocateProjectMutation } from '../our-forest/store/api' 
import {Loader} from '../../../_metronic/partials'
import {useAppDispatch} from '../../store/store'
import {updateNotification} from '../../store/slices/notifier'
import {NotificationType} from '../../../_metronic/partials/components/types'
import { commaSeparatedFormat } from '../../utils/helpers'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'


const RenderOnboardedCell = ({cell}:{cell:any}) =>(
  <p className='center-align'>{commaSeparatedFormat(cell.row.original.onboarded)}/{commaSeparatedFormat(cell.row.original.total)}</p>
)

const RenderCorporateEmissionCell = ({cell}:{cell:any}) =>(
  <p className='center-align'>{commaSeparatedFormat(Math.round(cell.row.original.emission))}</p>
)

const RenderOffsetCell = ({cell}:{cell:any}) =>(
  <p className='center-align'>{commaSeparatedFormat(cell.row.original.offset?.toFixed(2))}</p>
)

const RenderAllocationPerEmployeeCell = ({cell}:{cell:any}) =>(
  <p className='allocation-box2'>{commaSeparatedFormat(Math.round(cell.row.original.unite_allocated_per_employee))}</p>
)

type allocationDivisonDataObjectType = {
  name:string
  per_employee_unit_allocation: number
}

const AttributeToEmployees = () => {
  const intl = useIntl()
  const {projectId} = useParams()
  const [emissions, setEmissions] = useState<{[key: string]: number}>()
  const [focusedRow, setFocusedRow] = useState<string>()
  const [activeButton ,setActiveButton] = useState(false);
  const [error, setError] = useState('')
  const {data: allocations, isLoading} = useGetAllocationsQuery();

  useEffect(() => {
    // tracking 
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.ForestsAllocatedProjectsPage})
  }, [])

  const [allocateProject, {isSuccess, isError}] = useAllocateProjectMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateNotification({
          message: 'Project allocated successfully',
          type: NotificationType.SUCCESS,
        })
      )
      navigate(RoutesPath.OUR_FOREST.ROOT)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: 'Something went wrong',
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError])

  const handleEmissionOffsetChange = (offset: string, id: string) => {
    
    setFocusedRow(id)
    let offsetInputValue = parseInt(offset, 10)
    if (!offset) {
      offsetInputValue = 0
    }
    if (offsetInputValue >= 0 && offsetInputValue <= 100) {
      setEmissions((prevEmissions) => ({...prevEmissions, [id]: offsetInputValue}))
    }
  }

  const headers = useMemo(
    () => [
      {Header: 'DEPARTMENT', accessor: 'name'},
      {Header: 'EMPLOYEES ONBOARDED/INVITED', 
        accessor: 'onboarded',  
        Cell: ({cell}: {cell: any}) => <RenderOnboardedCell cell={cell} />
      },
      {Header: 'UNITS ALLOCATED PER EMPLOYEE',
       accessor: 'unite_allocated_per_employee',
       Cell: ({cell}: {cell: any}) =><RenderAllocationPerEmployeeCell cell={cell} /> },
      {
        Header: 'CORPORATE EMISSIONS (kgCO2)',
        accessor: 'emission',
        Cell: ({cell}: {cell: any}) => <RenderCorporateEmissionCell cell={cell} />
      },
      {Header: '% OF EMISSIONS RECAPTURED',
       accessor: 'offset',
       Cell: ({cell}: {cell: any}) => <RenderOffsetCell cell={cell} />},
    ],
    [emissions]
  )

  const onClickAllocate = () => {
    if (emissions) {
      const sum = Object.values(emissions).reduce((partialSum, offset) => partialSum + offset, 0)
      if (sum > 100) {
        setError(intl.formatMessage({id: 'OUR_FOREST.ALLOCATE_PROJECT.ERROR'}))
      } else {
        allocateProject({
          guid: projectId,
          allocations: Object.keys(emissions).map((key) => ({
            department_guid: key,
            percentage_allocation: emissions[key],
          })),
        })
      }
    }
  }

  const projectAllocationList = allocations?.allocations || []
  const allocatedUnits = allocations?.allocate_unit || 0;
  const total_units = allocations?.total_unit  || 0;

  return (
    <div className='custom-main-div'>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <div className='back-header-btn px-16 mt-8'>
            <button 
              type='button' 
              className='p-2 back-left-arrow-btn-wrap'
              onClick={()=>{navigate(RoutesPath.FOREST.ROOT)}}
            >
              <div className='back-left-arrow-btn' /> 
            </button>
          </div>
          
          <div className='mt-4 mx-18 pb-12'>
            <div className='d-flex flex-row justify-content-between  align-items-center'>
              <h3 className='captialise'>Allocation of Recapture</h3>
              <div className='d-flex flex-row align-items-center'>
                <p className='sub-heading px-4'>ALLOCATED UNITS</p>
                <p className='me-10 allocation-box'>{commaSeparatedFormat(allocatedUnits)}</p>
                <p className='sub-heading px-4'>REMAINING UNITS</p>
                <p className='me-10 allocation-box'>{commaSeparatedFormat(total_units - allocatedUnits)}</p>
              </div>
            </div>
          
            <ReactTable
              headerData={headers}
              tableData={projectAllocationList}
              className='mt-6 custom-border'
              currentPageIndex={0}
              disableSort
              showPagination={false}
              message={intl.formatMessage({id: 'DASHBOARD.DEPARTMENTS.NO_RESULTS_FOUND'})}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default AttributeToEmployees
