import { useDispatch, useSelector } from "react-redux";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { setScopeSurveyFormField, setSectionStatus, FORM_FIELDS, SectionStatus } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";
import { Dropdown } from "../../../../ui/components/dropdown";

const AccordionDivThree = (props: AccordionDivProps) => {
  const {data} = props;
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);

  const handleFieldChange = (field: FORM_FIELDS, value: any) => {
    dispatch(setScopeSurveyFormField({field, data: value}));

    // Create updated state with new value
    const updatedState = {
      ...formState,
      [field]: value
    };

    // Check status after field update using updated state
    const fieldsToCheck = [
      updatedState.numberOfLightTravelers,
      updatedState.lightTravelersLocalTrips,
      updatedState.lightTravelersLocalClass,
      updatedState.lightTravelersMediumTrips,
      updatedState.lightTravelersMediumClass,
      updatedState.lightTravelersLongTrips,
      updatedState.lightTravelersLongClass
    ];

    const isStarted = fieldsToCheck.some(fieldValue => 
      fieldValue !== undefined
    );

    const isCompleted = fieldsToCheck.every(fieldValue => 
      fieldValue !== undefined
    );

    let status: SectionStatus = "not-started";
    if (isCompleted) {
      status = "completed";
    } else if (isStarted) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "lightTravelersStatus",
      status
    }));
  };

  const travelClassOptions = [
    {label: 'Economy', value: 'Economy'},
    {label: 'Premium Economy', value: 'Premium Economy'},
    {label: 'Business', value: 'Business'},
    {label: 'First Class', value: 'First Class'}
  ];

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='threeSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-threeSection' 
            aria-expanded="false" 
            aria-controls='panel-threeSection'
          >
            <p className='report-sec-header' style={{color: "black"}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-threeSection' className="accordion-collapse collapse" aria-labelledby='threeSection'>
          <div className="accordion-body">
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Number of Light Travelers'
                  value={formState.numberOfLightTravelers}
                  onChange={(v) => handleFieldChange('numberOfLightTravelers', v)}
                  placeholder='Enter Number of Employees'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Local (<3h): Avg Trips/Person/Quarter'
                  value={formState.lightTravelersLocalTrips}
                  onChange={(v) => handleFieldChange('lightTravelersLocalTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
              <div className='col-4'>
                <Dropdown
                  label='Local Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('lightTravelersLocalClass', v)}
                  selectedValue={formState.lightTravelersLocalClass}
                  placeholder='Select Travel Class'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Medium (4-7h): Avg Trips/Person/Quarter'
                  value={formState.lightTravelersMediumTrips}
                  onChange={(v) => handleFieldChange('lightTravelersMediumTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
              <div className='col-4'>
                <Dropdown
                  label='Medium Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('lightTravelersMediumClass', v)}
                  selectedValue={formState.lightTravelersMediumClass}
                  placeholder='Select Travel Class'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Long (>7h): Avg Trips/Person/Quarter'
                  value={formState.lightTravelersLongTrips}
                  onChange={(v) => handleFieldChange('lightTravelersLongTrips', v)}
                  placeholder='Enter Average Trips'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <Dropdown
                  label='Long Distance Travel Class'
                  options={travelClassOptions}
                  onChange={(v) => handleFieldChange('lightTravelersLongClass', v)}
                  selectedValue={formState.lightTravelersLongClass}
                  placeholder='Select Travel Class'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionDivThree
