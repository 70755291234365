import {createSlice} from '@reduxjs/toolkit'

type mastertListType = {
  departmentsList: any[],
  divisionsList: any[],
  locationsList: any[],
  activeDepartmentsList: any[],
  activeDivisionsList: any[],
  activeLocationsList: any[],
  liveCustomGroupsList: any[],
  countryList: any[],
  stateList: any[],
  cityList: any[]
}

const initialState: mastertListType = {
  departmentsList: [], 
  divisionsList: [],
  locationsList: [],
  activeDepartmentsList: [],
  activeDivisionsList: [],
  activeLocationsList: [],
  liveCustomGroupsList: [],
  countryList: [],
  stateList: [],
  cityList: []
}

export const masterListSlice = createSlice({
  name: 'materList',
  initialState,
  reducers: {
    setDepartmentsList: (state, action) => {
      state.departmentsList = action.payload;
    },
    setDivisionsList: (state, action) => {
      state.divisionsList = action.payload;
    },
    setLocationsList: (state, action) => {
      state.locationsList = action.payload;
    },
    setActiveDepartmentsList: (state, action) => {
      state.activeDepartmentsList = action.payload;
    },
    setActiveDivisionsList: (state, action) => {
      state.activeDivisionsList = action.payload;
    },
    setActiveLocationsList: (state, action) => {
      state.activeLocationsList = action.payload;
    },
    setLiveCustomGroupsList: (state, action) => {
      state.liveCustomGroupsList = action.payload;
    },
    setCountryList: (state, action) => {
      state.countryList = action.payload;
    },
    setStateList: (state, action) => {
      state.stateList = action.payload;
    },
    setCityList: (state, action) => {
      state.cityList = action.payload;
    }
  },
})

export const {
  setDepartmentsList, 
  setDivisionsList, 
  setLocationsList,
  setActiveDepartmentsList,
  setActiveDivisionsList,
  setActiveLocationsList,
  setLiveCustomGroupsList,
  setCountryList,
  setStateList,
  setCityList
} = masterListSlice.actions

export default masterListSlice.reducer