import {useSelector} from 'react-redux'
import {Navbar, Nav, Image} from 'react-bootstrap'
import {RootState} from '../../../../app/store/store'

function HeaderToolbarSettings() {
  const userDetails = useSelector((state: RootState) => state.profile.profile)

  return (
    <Navbar className='navbar-settings navbar-expand-lg'>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav' className='justify-content-between px-6'>
        <Nav className='mr-auto'>
          {/* <Button type="button" className="btn-custom-date btn-outline-primary">+ Custom Date</Button> */}
        </Nav>
        <Nav className='ml-auto d-flex flex-row align-items-center'>
          {/* <Nav.Link href="#notifications"><i className="fas fa-bell" /></Nav.Link> */}
          <Image
            src={userDetails?.organization?.logo}
            roundedCircle
            width={40}
            height={40}
            className='ml-5'
          />
          <div className='d-flex flex-column justify-content-center mx-2 '>
            <span className='m-0 nav-link-s'>{userDetails?.name}</span>
            <span className='m-0 nav-link-sa'>Admin</span>
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export {HeaderToolbarSettings}
