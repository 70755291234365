import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { 
  projected2030EmissionsDonutData, 
  projected2040EmissionsDonutData, 
  projected2050EmissionsDonutData, 
  reductionInitiativesData, 
  TOTAL_EMISSIONS_VALUE, 
  totalScopeEmissionsDonutData,
  totalScopeEmissionsRecaptureData
} from './docs-data'
import { RoutesPath } from '../../routing/RoutesPath'
import CustomDonutGapChart from './charts/custom-donut-gap-chart'
import ReductionInitiativesTable from './components/reduction-initiatives-table'
import CircularGaugeRecaptureChart from './charts/circular-guage-recapture-chart'

const CHART_HEIGHT = 270;
const CHART_WIDTH = 270;

const styles: Record<string, React.CSSProperties> = {
  recaptureMarker: {
    width: '20px',
    height: '6px',
    borderRadius: '3px',
    // backgroundColor: '#58AF86',
    background: 'linear-gradient(to right, #58AF86, #87D4F9)',
    marginRight: '4px'
  },
  recaptureText: {
    fontSize: '12.5px'
  }
} as const

const ProjectedEmissionsPage = () => {
  const navigate = useNavigate();

  return (
    <div className="blank-page-view pt-8 pb-16 px-8">
      <div className='back-header-btn'>
          <button 
            type='button' 
            className='p-2 back-left-arrow-btn-wrap'
            onClick={()=>{navigate(RoutesPath.DASHBOARD.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
      </div>
      <div className='p-0 m-0 mt-4'>
        <h1 className='m-0' style={{fontWeight: 600}}>PROJECTED EMISSIONS</h1>
      </div>
      <div className='mt-2 d-flex justify-content-evenly flex-wrap'>
        <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
          <h3 style={{fontWeight: 600, textAlign: 'right'}}>CURRENT</h3>
          <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
            <CustomDonutGapChart data={totalScopeEmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={TOTAL_EMISSIONS_VALUE} zIndex={2} />
            <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
              <div style={styles.recaptureMarker}  />
              <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData.current}</span> tCO2e)</span></p>
            </div>
            <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '47.5%', left: '51%'}}>
              <CircularGaugeRecaptureChart 
                donutChartData={{data: totalScopeEmissionsDonutData, totalValue: TOTAL_EMISSIONS_VALUE}} 
                percentage={(totalScopeEmissionsRecaptureData.current/TOTAL_EMISSIONS_VALUE)*100} 
                data={totalScopeEmissionsRecaptureData.current} 
                chartHeight={`${CHART_HEIGHT * 1.03}px`} 
              />
            </div>  
          </div>
        </div>
        <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
          <h3 style={{fontWeight: 600, textAlign: 'right'}}>2030</h3>
          <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
            <CustomDonutGapChart data={projected2030EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={TOTAL_EMISSIONS_VALUE} zIndex={2} />
            <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
              <div style={styles.recaptureMarker}  />
              <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2030']}</span> tCO2e)</span></p>
            </div>
            <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '46.5%', left: '50.4%'}}>
              <CircularGaugeRecaptureChart 
                donutChartData={{data: projected2030EmissionsDonutData, totalValue: TOTAL_EMISSIONS_VALUE}} 
                percentage={(totalScopeEmissionsRecaptureData['2030']/TOTAL_EMISSIONS_VALUE)*100} 
                data={totalScopeEmissionsRecaptureData['2030']} 
                chartHeight={`${CHART_HEIGHT * 1.03}px`} 
              />
            </div>  
          </div>
        </div>
        <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
          <h3 style={{fontWeight: 600, textAlign: 'right'}}>2040</h3>
          <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
            <CustomDonutGapChart data={projected2040EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={500} zIndex={2} />
            <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
              <div style={styles.recaptureMarker}  />
              <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2040']}</span> tCO2e)</span></p>
            </div>
            <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '46.5%', left: '50.4%'}}>
              <CircularGaugeRecaptureChart 
                donutChartData={{data: projected2040EmissionsDonutData, totalValue: TOTAL_EMISSIONS_VALUE}} 
                percentage={(totalScopeEmissionsRecaptureData['2040']/TOTAL_EMISSIONS_VALUE)*100} 
                data={totalScopeEmissionsRecaptureData['2040']} 
                chartHeight={`${CHART_HEIGHT * 1.03}px`} 
              />
            </div>  
          </div>
        </div>
        <div className='m-0 mt-6 px-0' style={{width: '24%', minWidth: `${CHART_WIDTH}px`}}>
          <h3 style={{fontWeight: 600, textAlign: 'right'}}>2050</h3>
          <div className='m-0 px-0 py-2 bordered-grey position-relative' style={{zIndex: 0}}>
            <CustomDonutGapChart data={projected2050EmissionsDonutData} chartHeight={`${CHART_HEIGHT}px`} totalValue={500} zIndex={2} />
            <div className='mb-2 d-flex justify-content-center align-items-center' style={{marginTop: '-10px'}}>
              <div style={styles.recaptureMarker}  />
              <p className='m-0' style={styles.recaptureText}>Recapture <span style={{fontSize: '11px'}}>(<span style={{fontWeight: 700, fontSize: '11.5px'}}>{totalScopeEmissionsRecaptureData['2050']}</span> tCO2e)</span></p>
            </div>
            <div className='position-absolute translate-middle' style={{zIndex: 1, height: '100%', width: '100%', top: '46.5%', left: '50.4%'}}>
              <CircularGaugeRecaptureChart 
                donutChartData={{data: projected2050EmissionsDonutData, totalValue: TOTAL_EMISSIONS_VALUE}} 
                percentage={(totalScopeEmissionsRecaptureData['2050']/TOTAL_EMISSIONS_VALUE)*100} 
                data={totalScopeEmissionsRecaptureData['2050']} 
                chartHeight={`${CHART_HEIGHT * 1.03}px`} 
              />
            </div>  
          </div>
        </div>
      </div>
      <div className='mt-14'>
        <h2 className='m-0' style={{fontWeight: 600}}>REDUCTION INITIATIVES</h2>
        <ReductionInitiativesTable listData={reductionInitiativesData} />
      </div>
      <div className='mt-8'>
        <button
          type='button'
          className='custom-primary-delete-button mb-0 mx-0'
          style={{padding: '8px 20px', fontWeight: 600}}
          onClick={()=> navigate(RoutesPath.FOREST.ROOT)}
          >
          RECAPTURE RESIDUAL EMISSIONS
        </button>
      </div>
    </div>
  )
}

export default ProjectedEmissionsPage
