/* eslint no-nested-ternary: "error" */

import {useCallback, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { useLazyGetNetZeroPercentageQuery } from '../../dashboard/store/api'
import { getLocalStorageItem } from '../../../utils/storage'
import { getYYYYDDMMFormat } from '../../../utils/dateFormatter'
import '../styles.css'

const NetZeroStatusChart = () => {
  const {profile} = useSelector((state) => state.profile)
  const {filterYear} = useSelector((state) => state.filters)
  
  const [getNetZeroPercentage, {
    data: getNetZeroPercentageData
  }] = useLazyGetNetZeroPercentageQuery()

  const liveOnDate = new Date(getLocalStorageItem('live-on'))

  const timePeriodFilter = liveOnDate.getUTCMonth().toString()

  const getDateFilters = useCallback(() => {
    const currentDate = new Date()
    if (timePeriodFilter) {
      const selectedMonth = parseInt(timePeriodFilter, 10)
      const selectedYear =
        selectedMonth > currentDate.getUTCMonth()
          ? currentDate.getUTCFullYear() - 1
          : currentDate.getUTCFullYear()

      const fromDate = getYYYYDDMMFormat(
        new Date(selectedYear, parseInt(timePeriodFilter, 10), 1)
      )

      const toDate = (profile?.organization?.isWorkshopOrg) ? 
        // (netZeroToDateLocal || moment(profile?.organization?.workshopDate).add(89, 'days').format('YYYY-MM-DD'))
        '2030-12-31'
        : getYYYYDDMMFormat(currentDate)
      return {
        fromDate,
        toDate,
      }
    }
    return undefined
  }, [timePeriodFilter])
    
  useEffect(() => {
    if (timePeriodFilter) {
      getNetZeroPercentage({
        ...(timePeriodFilter && {...getDateFilters()}),
      })
    }
  }, [timePeriodFilter]) 

  const percentageNetZeroRecapture = Math.round(getNetZeroPercentageData?.data?.percentage || 0);

    
  return ( 
    <div className='d-flex align-items-center flex-column m-0 p-0 position-relative'>
      <div className='d-flex flex-column w-100 mb-5 align-items-center'>
        <div className='semi-donut-model-net-zero mt-2' style={{'--percentage': `${percentageNetZeroRecapture > 100 ? 100 : percentageNetZeroRecapture  }`}}>
          <div className='d-flex flex-column mt-3 align-items-center'>
          <span className='percent-val' style={{fontWeight: 800, fontSize: '18px'}}>
            {
              (profile?.organization?.isWorkshopOrg && filterYear !== '2030') ? 
                '--' : 
                percentageNetZeroRecapture
            }%
          </span>
          <span className='percent-val' style={{fontSize: '14px'}}>
            Recapture
          </span>
          </div>
        </div>
      </div>
    </div>
  )
}
  export {NetZeroStatusChart}
  
/* eslint no-nested-ternary: "error" */