export const styles: Record<string, React.CSSProperties> = {
  input: {
    width: '100%',
    borderWidth: 0,
    outline: 'none',
    fontSize: 15,
    fontWeight: 600,
  },
  inputText: {
    width: '100%',
    borderWidth: 0,
    outline: 'none',
    fontSize: 15,
    fontWeight: 600,
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputNumber: {
    width: '100%',
    borderWidth: 0,
    outline: 'none',
    fontSize: 15,
    fontWeight: 600,
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  greyBorder: {
    borderRadius: 10,
    border: '1px solid #d8d8d8',
    overflow: 'hidden',
  },
  label: {
    fontSize: 14,
    fontWeight: 600,
    color: '#666666',
    marginBottom: 5,
  },
  inputNumberUnit: {
    fontSize: 14,
    fontWeight: 600,
    color: '#666666',
    borderStyle: 'solid',
    borderColor: '#d8d8d8',
    borderWidth: '0px 0px 0px 1px',
    margin: '0px 0px 0px 10px',
    padding: '0px 5px',
  }
}