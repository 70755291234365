/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

type Props = {
  chartHeight: string
  title?: string
  donutChartData?: any | undefined
  data?: any | undefined
  percentage: number
}

const CircularGaugeRecaptureChart: React.FC<Props> = ({
  chartHeight,
  title = "",
  donutChartData,
  data,
  percentage
}) => {

  let gapPercentage = (donutChartData.totalValue - donutChartData.data.reduce((acc: number, item: any) => acc + item.count, 0)) / donutChartData.totalValue;
  gapPercentage = gapPercentage < 0 ? 0 : gapPercentage * 100;


  const chartref = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const refreshMode = () => {
    if (!chartref.current || !donutChartData.data) {
      return undefined
    }

    const chartOptions: ApexOptions = {
      chart: {
        type: 'radialBar',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#58AF86"],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: "80%"
          },
          track: {
            background: '#f2f2f2',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
            total: {
              show: false,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          gradientToColors: ["#87D4F9"],
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: "round",
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },
      series: [percentage],
      labels: [""],
      legend: {
        show: false,
      },
      tooltip: {
        enabled: true,
        custom: ({series, seriesIndex, dataPointIndex, w}) => (
          `${data} tCO2e`
        )
      }
    }
    const chart = new ApexCharts(chartref.current, chartOptions);
    chart.render();
    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart !== undefined) {
        ;(chart as ApexCharts).destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  return (
    <div className='card m-0' style={{backgroundColor: 'transparent'}}>
      {/* begin::Beader */}
      {title.length > 0 && (
        <div className='card-header border-0 pt-2' style={{backgroundColor: 'transparent'}}>
          <h3 className='card-title align-items-center flex-row' style={{backgroundColor: 'transparent'}}>
            <span className='card-label fw-bold fs-3 mb-1' style={{color: '#transparent'}}>{title.toUpperCase()}</span>
          </h3>
        </div>
      )}
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body-chart d-flex flex-column mb-0' style={{backgroundColor: 'transparent'}}>
        <div className='flex-grow-1'>
          <div 
            ref={chartref} 
            className='mixed-widget-4-chart pt-0 pb-0' 
            style={{
              backgroundColor: 'transparent',
              transform: `rotate(${((100-gapPercentage))*3.6}deg) scaleX(-1)`,
              height: '100%', 
              width: '100%'
            }}
          />
        </div>
      </div>
      {/* end::Body */}

    </div>
  ) 
}

export default CircularGaugeRecaptureChart;
