import { IconEmployeesReport } from "../../../../_metronic/assets/icons"

const HighlightsCard: React.FC = () => (
  <div className="position-relative">
    <div className="px-16 py-3" style={{ display: 'inline-block', borderRadius: '8px 8px 0px 0px', background: 'linear-gradient(to right, #563d7c 0%,  #6f42c1 50%, #563d7c 100%)'}}>
      <h2 className="m-0" style={{ fontSize: '1.25rem', color: '#ffffff', }}>HIGHLIGHTS</h2>
    </div>
    
    <div className="d-flex bordered-grey" style={{zIndex: 1000, borderRadius: '0px 8px 8px 8px'}}>
      {/* Left Card */}
      <div 
        style={{ 
          flex: 1, 
          backgroundColor: '#f0f7ff', 
          borderRadius: '0px 0 0 8px', 
          padding: '16px',
          borderRight: '1px solid #e0e0e0'
        }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
          <div 
            style={{ 
              width: '58px', 
              height: '58px',
              marginRight: '12px',
              borderRadius: '28px',
              background: 'linear-gradient(to top, #6f42c1, #563d7c)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
            <IconEmployeesReport height={24} width={24} />
          </div>
          <p style={{ fontSize: '1.1rem', margin: 0, width: '80%' }}>
            Emission per employee (<span style={{fontWeight: 600}}>2.5</span> tCO2e) is <span style={{fontWeight: 600}}>~9%</span> higher than an industry average (<span style={{fontWeight: 600}}>~2.3</span> tCO2e)
          </p>
        </div>
      </div>

      {/* Right Card */}
      <div 
        style={{ 
          flex: 1, 
          backgroundColor: '#f3f0ff', 
          borderRadius: '0 8px 8px 0', 
          padding: '16px'
        }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'relative' }}>
          <div 
            style={{ 
              width: '58px', 
              height: '58px',
              marginRight: '12px',
              borderRadius: '28px',
              background: 'linear-gradient(to top, #6f42c1, #563d7c)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
            <IconEmployeesReport height={24} width={24} />
          </div>
          <p style={{ fontSize: '1.1rem', margin: 0, width: '80%' }}>
            Total emissions decreased by <span style={{fontWeight: 600}}>~3%</span> from the previous year
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default HighlightsCard