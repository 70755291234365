import { useNavigate } from 'react-router-dom'
import EmissionDonutContributionChart from './charts/emission-donut-contribution-chart'
import { HeaderInfoCard } from './components/header-info-card'
import { TopCategoryEmission } from './charts/top-categories-emission-chart'
import { locationEmissionData, topCategoriesEmissionData, totalScopeEmissionsDonutData } from './docs-data'
import { NetZeroStatusCard } from './charts/net-zero-status-circle'
import HighlightsCard from './components/highlights-card'
import LocationEmissionTable from './components/location-emission-table'
import { RoutesPath } from '../../routing/RoutesPath'

const DashboardPage = () => {
  // TODO: Implement DashboardPage
  const navigate = useNavigate()
  return (
    <div className="blank-page-view pt-6 pb-16 px-6">
      <div className="row p-0 m-0">
        <div className="col-4 m-0 pb-0">
          <h2 className='m-0'>EMISSIONS</h2>
          <div className='mt-4 bordered-grey py-4'>
            <EmissionDonutContributionChart chartHeight='280px' title='' data={totalScopeEmissionsDonutData} />
          </div>
        </div>
        <div className="col-8">
          <h2 className='m-0'>TOP CATEGORIES</h2>
          <div className='mt-4 d-flex'>
            <div className='p-0 my-0' style={{width: '65%'}}>
              <TopCategoryEmission data={topCategoriesEmissionData} />
            </div>
            <div className='p-0 my-0 d-flex flex-column justify-content-between' style={{width: '35%', marginLeft: '20px'}}>
              <div className='p-0 m-0 position-relative' style={{height: '46%'}}>
                <HeaderInfoCard label='EMISSIONS PER EMPLOYEE' value='2.5' unit='tCO2e' />
              </div>
              <div className='p-0 m-0 position-relative' style={{height: '46%'}}>
                <HeaderInfoCard label='EMISSIONS BY REVENUE' value='500' unit='tCO2e' />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row p-0 m-0 mt-14'>
        <div className='d-flex justify-content-between align-items-center'>
          <h2 className='m-0'>NET ZERO STATUS</h2>
          <button
            type='button'
            className='custom-primary-delete-button mb-0 mx-0'
            style={{padding: '8px 20px', fontWeight: 600}}
            onClick={()=> navigate(RoutesPath.DASHBOARD.PROJECTED_EMISSIONS)}
            >
            PROJECTED EMISSIONS
          </button>
        </div>
        <div className='col-3'>
          <div className='mt-2'>
            <NetZeroStatusCard label='NET ZERO STATUS' isAvailable borderClass='bordered-grey' />
          </div>
        </div>
        <div className='col-9'>
          <div className='p-0 m-0 h-100 d-flex align-items-end'>
            <HighlightsCard />
          </div>
        </div>
      </div>

      <div className='row p-0 m-0 mt-14'>
        <div className='col-12'>
          <h2 className='m-0'>LOCATION</h2>
          <div className='mt-4'>
            <LocationEmissionTable listData={locationEmissionData} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage
