/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

const CHART_LABEL_COLORS = ['#362C56', '#7B6CB2', '#A687CA'];

type Props = {
  chartHeight: string
  title?: string
  data?: any | undefined
}

const EmissionDonutContributionChart: React.FC<Props> = ({
  chartHeight,
  title = "",
  data,
}) => {

  const chartref = useRef<HTMLDivElement | null>(null);
  const {mode} = useThemeMode();

  const refreshMode = () => {
    if (!chartref.current || !data) {
      return undefined
    }

    const chartData = {
      series: data.map((item: any) => Math.round(item.count)),
      labels: data.map((item: any) => item.name ?? ''),
    }

    const chartOptions: ApexOptions = {
      chart: {
        type: 'donut',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (value: number) => `${Math.round(value)}%`,
        textAnchor: 'start',
        style: {
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 500,
          colors: ['#fff'], // Customize the label color
        },
      },
      colors: CHART_LABEL_COLORS,
      plotOptions: {
        pie: {
          expandOnClick: false,
          dataLabels: {
            offset: -5
          },
          donut: {
            size: '45%',
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: 16,
              },
            },
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },

      series: chartData.series,
      labels: chartData.labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 0,
        markers: {
          width: 12,
          height: 12,
          radius: 4,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        itemMargin: {
          horizontal: 5,
        },
      },

      tooltip: {
        enabled: true,
        custom: ({series, seriesIndex}) => (
          `<div>
            <p class="m-0 p-2" style="font-size: 13px; font-weight: 500; color: #FFF;">
              ${chartData.labels[seriesIndex]}: <span style="font-weight: 800;">${series[seriesIndex]}</span> tCO2e
            </p>
          </div>`
        )
      }
    }
    const chart = new ApexCharts(chartref.current, chartOptions);
    chart.render();
    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart !== undefined) {
        ;(chart as ApexCharts).destroy()
      }
    }
  }, [chartref, mode, refreshMode])

  return (
    <div className='card m-0'>
      {/* begin::Beader */}
      {title.length > 0 && (
        <div className='card-header border-0 pt-2'>
          <h3 className='card-title align-items-center flex-row'>
            <span className='card-label fw-bold fs-3 mb-1'>{title.toUpperCase()}</span>
          </h3>
        </div>
      )}
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body-chart d-flex flex-column mb-0'>
        <div className='flex-grow-1'>
          <div ref={chartref} className='mixed-widget-4-chart pt-0 pb-0' />
        </div>
      </div>
      {/* end::Body */}

    </div>
  ) 
}

export default EmissionDonutContributionChart;
