import { useDispatch, useSelector } from "react-redux";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { Dropdown } from "../../../../ui/components/dropdown";
import { setSectionStatus, OfficeDetail, SectionStatus, setOfficeDetail, addNewOffice, removeOffice } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";
import { getStateList, getCityList } from "../api";

const AccordionDivOne = (props: AccordionDivProps) => {
  const {data} = props;
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);
  const countryList = useSelector((state: RootState) => state.masterList.countryList);

  const handleFieldChange = async (index: number, field: keyof OfficeDetail, value: any) => {
    if (field === 'country') {
      const selectedCountry = countryList.find(country => country.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedCountry.mg_id, 
          name: selectedCountry.mg_name 
        }
      }));

      try {
        const states = await getStateList(value);
        dispatch(setOfficeDetail({
          index,
          field: 'stateList',
          data: states.data
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'state', 
          data: { id: null, name: '' }
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'city', 
          data: { id: null, name: '' }
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'cityList',
          data: []
        }));
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    } else if (field === 'state') {
      const selectedState = formState.officeDetails[index].stateList.find(state => state.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedState.mg_id, 
          name: selectedState.mg_name 
        }
      }));

      try {
        const cities = await getCityList(value);
        dispatch(setOfficeDetail({
          index,
          field: 'cityList',
          data: cities.data
        }));
        dispatch(setOfficeDetail({
          index,
          field: 'city', 
          data: { id: null, name: '' }
        }));
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    } else if (field === 'city') {
      const selectedCity = formState.officeDetails[index].cityList.find(city => city.mg_id === value);
      dispatch(setOfficeDetail({
        index,
        field, 
        data: { 
          id: selectedCity.mg_id, 
          name: selectedCity.mg_name 
        }
      }));
    } else {
      dispatch(setOfficeDetail({index, field, data: value}));
    }

    // Make copy of form state with updated field
    const updatedOfficeDetails = formState.officeDetails.map((office, i) => {
      if (i === index) {
        return {
          ...office,
          [field]: data
        }
      }
      return office;
    });

    const isAnyFieldFilled = updatedOfficeDetails.some(office => 
      office.country?.id || 
      office.totalOfficeSquareFootage !== undefined || 
      office.numberOfEmployeesInOffice !== undefined ||
      office.averageWFHDaysPerWeek !== undefined ||
      office.isIGBCCertified !== '' ||
      office.isRenewableEnergySourced !== ''
    );

    const areAllFieldsFilled = updatedOfficeDetails.every(office => 
      office.country?.id && 
      office.totalOfficeSquareFootage !== undefined && 
      office.numberOfEmployeesInOffice !== undefined &&
      office.averageWFHDaysPerWeek !== undefined &&
      office.isIGBCCertified !== '' &&
      office.isRenewableEnergySourced !== ''
    );

    let status: SectionStatus = "not-started";
    if (areAllFieldsFilled) {
      status = "completed"; 
    } else if (isAnyFieldFilled) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "officeElectricityStatus", 
      status
    }));
  };

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='oneSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-oneSection' 
            aria-expanded="false" 
            aria-controls='panel-oneSection'
            style={{borderWidth: '0px !important', borderColor: 'transparent !important'}}
          >
            <p className='report-sec-header' style={{color: "black"}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-oneSection' className="accordion-collapse collapse" aria-labelledby='oneSection'>
          <div className="accordion-body">
            {formState.officeDetails.map((office, index) => (
              <div key={`office-${office.country.id || index}-${office.state.id || index}-${office.city.id || index}`} className="office-section mb-4">
                { 
                  index > 0 && (
                    <>
                      <div className="mb-8 position-relative">
                        <hr className="mx-8" color="#C1C1C1" />
                        <button 
                          type='button' 
                          className='remove-button-styles fs-2' 
                          style={{
                            position: 'absolute',
                            right: 15,
                            top: -15,
                            height: 30,
                            width: 30,
                            borderRadius: 15,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'black',
                            backgroundColor: '#E4DFDF',
                            border: 'none',
                            padding: 5,
                            boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
                            fontWeight: 600
                          }} 
                          onClick={() => dispatch(removeOffice(index))}
                          >
                          <p className="m-0">x</p>
                        </button>
                      </div>
                      <h4 className="mb-0 mx-8">OFFICE {index + 1}</h4>
                    </>
                  )
                }
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Country"
                      options={countryList.map(country => ({
                        label: country.mg_name,
                        value: country.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'country', v)}
                      selectedValue={office.country.id}
                      placeholder='Select Country'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="State"
                      options={office.stateList.map(state => ({
                        label: state.mg_name,
                        value: state.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'state', v)}
                      selectedValue={office.state.id}
                      disabled={!office.country.id}
                      placeholder='Select State'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="City"
                      options={office.cityList.map(city => ({
                        label: city.mg_name,
                        value: city.mg_id
                      }))}
                      onChange={(v) => handleFieldChange(index, 'city', v)}
                      selectedValue={office.city.id}
                      disabled={!office.state.id}
                      placeholder='Select City'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <InputNumber
                      label="Total Office Square Footage"
                      value={office.totalOfficeSquareFootage}
                      onChange={(v) => handleFieldChange(index, 'totalOfficeSquareFootage', v)}
                      placeholder='Enter Square Footage'
                    />
                  </div>
                  <div className='col-4'>
                    <InputNumber
                      label="Number of Employees In Office"
                      value={office.numberOfEmployeesInOffice}
                      onChange={(v) => handleFieldChange(index, 'numberOfEmployeesInOffice', v)}
                      placeholder='Enter Number Of Employees'
                    />
                  </div>
                  <div className='col-4'>
                    <InputNumber
                      label="Average WFH Days Per Week Per Employee"
                      value={office.averageWFHDaysPerWeek}
                      onChange={(v) => handleFieldChange(index, 'averageWFHDaysPerWeek', v)}
                      placeholder='Enter Average Days'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office LEED Gold Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isLEEDGoldCertified', v)}
                      selectedValue={office.isLEEDGoldCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office LEED Platinum Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isLEEDPlatinumCertified', v)}
                      selectedValue={office.isLEEDPlatinumCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                  <div className='col-4'>
                    <Dropdown
                      label="Is The Office IGBC Certified"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isIGBCCertified', v)}
                      selectedValue={office.isIGBCCertified}
                      placeholder='Select Yes/No'
                    />
                  </div>
                </div>
                <div className='row px-8 py-6'>
                  <div className='col-4'>
                    <Dropdown
                      label="Is Renewable Energy Sourced"
                      options={[{label: 'Yes', value: 'yes'}, {label: 'No', value: 'no'}]}
                      onChange={(v) => handleFieldChange(index, 'isRenewableEnergySourced', v)}
                      selectedValue={office.isRenewableEnergySourced}
                      placeholder='Select Yes/No'
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className='row px-8 py-6'>
              <div className='col-12 d-flex justify-content-end'>
                <button 
                  type='button'
                  className='remove-button-styles fs-5'
                  style={{borderRadius: 10, color: 'black', backgroundColor: 'white', border: '1px solid #E8E7E7', padding: '5px 20px', boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.1)', fontWeight: 500}}
                  onClick={() => dispatch(addNewOffice())}
                >
                  + Add Office
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionDivOne
