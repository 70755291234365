import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, Polygon, useJsApiLoader } from '@react-google-maps/api'
import { ForestDetailIndividual } from '../../../our-forest/types';

type LatLngType = {
  lat: number
  lng: number
}

type Props = {
  forest: ForestDetailIndividual
}

const ForestMapBoundary: React.FC<Props> = ({forest}) => {
  const [coordinatesArray, setCoordinatesArray] = useState<any[]>([])
  const [center, setCenter] = useState<LatLngType | null>(null)
  const [map, setMap] = useState<any>(null)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', 
    // libraries: _libArray
  })

  useEffect(() => {
    if (forest) {
      setCoordinatesArray(forest.forest_boundries.map(itemArray => JSON.parse(itemArray.boundry).map((item:any) => ({ lng: item[0], lat: item[1]}))));
    }
  }, [forest])

  useEffect(() => {
    if (coordinatesArray.length>0) {
      setCenter({
        lat:  coordinatesArray[0][0].lat,
        lng: coordinatesArray[0][0].lng
      })
    }
  }, [coordinatesArray])

  const onLoad = React.useCallback((tmap:any) => {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    if (forest && center) {
      const bounds = new window.google.maps.LatLngBounds(center);
      tmap.fitBounds(bounds);
  
      setMap(tmap)
    }
  }, [forest])

  const onUnmount = React.useCallback((tmap:any) => {
    setMap(null)
  }, [])

  return (
    <div style={{ height: "100%", width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: 5 }}>
      {
        (isLoaded && center) ? (
          <>
            <div className='my-3 mx-4'>
              <h5 className='m-0 sub-heading'>LOCATION</h5>
              <h6 className='m-0'>{forest.district} , {forest.villege}</h6>
            </div>
            <GoogleMap
              mapContainerStyle={{width: '100%', height: '85%', borderRadius: 10}}
              center={center}
              options={{
                streetViewControl: false,
                mapTypeControl: false,
                clickableIcons:false,
                zoomControl:true,
                fullscreenControl:true,
                mapTypeId: 'hybrid',
                zoom: 10, 
                maxZoom: 20, 
                minZoom: 4, 
              }}
              onLoad={onLoad}
              onUnmount={onUnmount}
              >
              {  
                coordinatesArray?.map(
                  array => 
                  <>
                    <Polygon
                      paths={array}
                      options={{
                        strokeColor:'#54ffff',
                        fillColor:'rgba(0, 0, 0, 0)',
                        strokeWeight:2,
                        zIndex:1,
                        // accessibilityLabel='Forest'
                      }}
                      />
                    <Marker
                      position={{
                        lat: array[0].lat, 
                        lng: array[0].lng
                      }}
                      />
                  </>
                )
              }
            </GoogleMap>
          </>
        ) : (
          <h4>
            Fetching Map..
          </h4>
        ) 
      }
    </div>
  )
}

export default ForestMapBoundary