/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import moment from 'moment'
import { DateRangePicker } from 'react-date-range'
import { StaticRange } from 'react-date-range'
import { useDispatch, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'

import { RoutesPath } from '../../routing/RoutesPath';
import HorizontalHeaderCard from './components/cards/horizontal-header-card';

import './styles.css';
import ForestCard from './components/cards/forest-card'
import SDGCard from './components/cards/sdg-card'
import { IconArrowRightCircle, IconForestContract, IconForestDonation, IconForestFarmerDetails, IconForestReceipt } from '../../../_metronic/assets/icons'
import ForestDocsPageCard from './components/cards/forest-docs-page-card'
import { ForestDocsType } from './types'
import { ForestsPlantationChart } from './components/charts/forest-plantation-chart'
import { forestDonutContributionChartData, forestPlantationChartData } from './docs-data'
import ForestDonutContributionChart from './components/charts/forest-donut-contribution-chart'
import { getYYYYDDMMFormat } from '../../utils/dateFormatter'
import { useGetTotalForestQuery } from '../our-forest/store/api'
import { getLocalStorageItem } from '../../utils/storage'
import { RootState } from '../../store/store'
import { ForestDetailIndividual } from '../our-forest/types'
import ForestMapBoundary from './components/maps/forest-map-boundary'
import ForestBoundaryCarousel from './components/carousels/forest-boundary-carousel'

type Props = {

}

const ForestPage: React.FC<Props> = () => {

  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();

  const liveOnDate = new Date(getLocalStorageItem('live-on'));
  const currentDate = new Date()
  const userDetails = useSelector((state: RootState) => state.profile.profile)

  const [forests, setForests] = useState<ForestDetailIndividual[]>([])

  const {data: forestsList, isFetching, isLoading} = useGetTotalForestQuery({
    fromDate: getYYYYDDMMFormat(liveOnDate), 
    toDate: userDetails?.organization?.isWorkshopOrg ? 
      // moment(userDetails?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD') : 
      '2030-12-31' :
      moment(currentDate).format('YYYY-MM-DD'),
  });

  useEffect(() => {
    if (forestsList?.forests?.length) {
      setForests(forestsList.forests)
    }
  }, [forestsList])
  
  return (
    <div className='blank-page-view pb-8 px-0 pt-0'>

      <div className='row px-6 mx-0 pt-6 pb-6' style={{backgroundColor: '#F8F8F8'}}>
        <div className='col-7 px-0 d-flex justify-content-between flex-column bordered-grey' style={{backgroundColor: 'white'}}>
          <ForestsPlantationChart series={forestPlantationChartData.series} categories={forestPlantationChartData.categories} disableAnimation={false} />
        </div>
        <div className='col-5' style={{padding: '0px 0px 0px 15px'}}>
          <HorizontalHeaderCard title='TOTAL ACRES PURCHASED' value={15} unit='acres' />
          <HorizontalHeaderCard title='RECAPTURE PER YEAR' value={33} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
          <HorizontalHeaderCard title='RECAPTURE THIS YEAR' value={30} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
          <HorizontalHeaderCard title='RECAPTURE TO DATE' value={64} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' isMarginBottom={false} />
        </div>
      </div>


      <div className='px-6 mx-0 mt-4 mb-4 d-flex justify-content-between align-items-end'>
        <h2 className='m-0'>FORESTS</h2>
        <button
          type='button'
          className='custom-primary-delete-button mb-0 mx-0'
          style={{padding: '8px 20px', fontWeight: 600}}
          onClick={()=> navigate(RoutesPath.FOREST.ATTRIBUTE_TO_EMPLOYEES)}
          >
          ATTRIBUTE TO EMPLOYEES
        </button>
      </div>

      <div className='row px-6 mx-0 gap-4'>
        <ForestCard forest={forests[0]} forestName='FOREST 1' companyName='Mynzo Carbon' forestNumber='ONE' location='Araku Valley, Andhra Pradesh, India' />
        <ForestCard forest={forests[0]} forestName='FOREST 2' companyName='Mynzo Carbon' forestNumber='TWO' location='Araku Valley, Andhra Pradesh, India' />
        <ForestCard forest={forests[0]} forestName='FOREST 3' companyName='Mynzo Carbon' forestNumber='THREE' location='Araku Valley, Andhra Pradesh, India' />
      </div>

      <div className='row px-6 mx-0 mt-14'>
          <h2 className='m-0 mb-4'>MEETING SDG GOALS</h2>
        <div className='col-6 px-0 d-flex flex-column position-relative' style={{backgroundColor: 'white'}}>
          {/* <h2 className='m-0 mb-4'>DEMOGRAPHICS</h2>
          <div className='bordered-grey flex-grow-1'>
            <ForestDonutContributionChart chartHeight='270px' title='FARMER DIVERSITY' data={forestDonutContributionChartData} />
          </div> */}
            <div className='d-flex flex-column justify-content-between flex-1 gap-4'>
              <SDGCard number={1} title='No Poverty' description='Agroforestry increases farmers income through diversified revenue streams such as timber, fruits, and crops.' />
              <SDGCard number={3} title='Climate Action' description='Agroforests act as natural carbon sinks, mitigating climate change through long-term sequestration.' />
            </div>
        </div>
        <div className='col-6' style={{padding: '0px 0px 0px 15px'}}>
          <div className='d-flex flex-row gap-4'>
            <div className='d-flex flex-column justify-content-between flex-1 gap-4'>
              <SDGCard number={2} title='Zero Hunger' description='Tree-based farming enhances food security by providing edible crops even during droughts.' />
              <SDGCard number={4} title='Life on Land' description='Agroforests mimic natural ecosystems, preserving biodiversity and restoring degraded lands.' />
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 mt-14">
        <h2 className='m-0 mb-4'>SATELLITE IMAGES</h2>
        <div className='d-flex flex-row gap-4' style={{height: '300px'}}>
          <div className='bordered-grey flex-1 position-relative' style={{width: '40%'}}>
            <ForestBoundaryCarousel />
          </div>
          <div className='bordered-grey flex-1 position-relative' style={{width: '60%'}}>
            {
              forests.length > 0 && (
                <ForestMapBoundary forest={forests[0]} />
              )
            }
          </div>
        </div>
      </div>

      <div className="px-6 mt-14">
        <h2 className='m-0 mb-4'>DETAILS</h2>
        <div className='d-flex flex-row gap-4' style={{height: '80px'}}>
          <ForestDocsPageCard title='CONTRACT' type={ForestDocsType.CONTRACT} icon={<IconForestContract height={28} width={28} />} />
          <ForestDocsPageCard title='FARMER DETAILS' type={ForestDocsType.FARMER_DETAILS} icon={<IconForestFarmerDetails height={28} width={28} />} />
          <ForestDocsPageCard title='DONATION' type={ForestDocsType.DONATION} icon={<IconForestDonation height={28} width={28} />} />
          <ForestDocsPageCard title='RECEIPT' type={ForestDocsType.RECEIPT} icon={<IconForestReceipt height={28} width={28} />} />
        </div>
      </div>

      <div className="my-10" />
      
    </div>
  )
}

export default ForestPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */