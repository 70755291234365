import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { DownloadIcon, IconUpload, IconUploadCircle } from '../../../../_metronic/assets/icons'
import { FilterScopes } from './components/filter-scopes'
import { RootState } from '../../../store/store'

const scopeOneCSVLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/reports/scope1.xlsx'
const scopeTwoCSVLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/reports/scope2.xlsx'

const UploadPage = () => {
  const [csvLink, setCsvLink] = useState<string>(scopeOneCSVLink);
  const {filterUploadScopesReport} = useSelector((state: RootState) => state.filters)

  useEffect(()=> {
    setCsvLink(`${filterUploadScopesReport === 'scope1' ? scopeOneCSVLink : scopeTwoCSVLink}`)
  }, [filterUploadScopesReport])

  return (
    <div className='blank-page h-100 pb-12' style={{backgroundColor: '#F8F8F8'}}>
      {/* Scope Toggle */}
      <div className='pt-6 pb-1 px-6' style={{backgroundColor: '#FFFFFF'}}>
        <FilterScopes />
      </div>

      {/* Upload Card */}
      <div className='px-6'>
        <div className='bordered-grey mt-8 py-6 px-8' style={{backgroundColor: '#FFFFFF'}}>
          <div className='d-flex align-items-center mb-4'>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center justify-content-center' style={{backgroundColor: '#ECECEC', borderRadius: '50%', width: 32, height: 32, marginRight: 10}}>
                <IconUploadCircle color='#0491BD' height={20} width={20} />
              </div>
              <h2 className='card-title mb-0' style={{fontWeight: 700}}>Upload your data</h2>
            </div>
          </div>

          <hr color="#CDCDCD" />

          {/* Steps */}
          <div className='my-10'>
            <h4 className='mb-3' style={{fontWeight: 700}}><span style={{fontWeight: 800}}>Step 1:</span> Download Template</h4>
            <p className='m-0 fs-5'>
              Click the button below to download the data entry template.
            </p>

            <h4 className='mb-3 mt-6' style={{fontWeight: 700}}><span style={{fontWeight: 800}}>Step 2:</span> Fill Out the Template</h4>
            <p className='m-0 fs-5'>
              Enter the required information about your {filterUploadScopesReport === 'scope1' ? 'scope one' : 'scope two'} emissions into the template. Ensure all fields are completed accurately.
            </p>

            <h4 className='mb-3 mt-6' style={{fontWeight: 700}}><span style={{fontWeight: 800}}>Step 3:</span> Upload Template</h4>
            <p className='m-0 fs-5'>
              Once filled, upload the completed template back to the platform using the upload button below.
            </p>

            <p className='mt-10 fs-5'>
              Our system will process your data and calculate your {filterUploadScopesReport === 'scope1' ? 'scope one' : 'scope two'} emissions.
            </p>
          </div>

          {/* Action Buttons */}
          <div className='d-flex justify-content-end gap-3'>
            <a
              href={csvLink}
              className='custom-primary-delete-button mb-0 mx-0'
              style={{padding: '8px 30px 8px 26px', fontWeight: 600, textDecoration: 'none'}}
              >
              <DownloadIcon height={20} width={20} style={{marginRight: 5}} /> Download Template
            </a>
            <button
              type='button'
              className='custom-outline-button-v2 mb-0 mx-0'
              style={{padding: '8px 30px 8px 26px', fontWeight: 600}}
              disabled
              // onClick={()=> {}}
              >
              <IconUpload height={14} width={14} style={{marginRight: 5}} /> Upload
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadPage