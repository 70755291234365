/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react'
import { commaSeparatedFormat } from '../../../utils/helpers'
import ReactTablePurple from '../../../../_metronic/partials/components/tables/ReactTablePurple'

// Render components for table cells
const RenderLocation = ({ value }: { value: string }) => (
  <div className="d-flex justify-content-center"><p className='m-0'>{value}</p></div>
)

const RenderNumber = ({ value }: { value: number }) => (
  <div className="d-flex justify-content-center"><p className='m-0'>{commaSeparatedFormat(value)}</p></div>
)

const LocationEmissionTable = ({ listData = [] }: { listData: any[] }) => {
  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>LOCATION</p></div>,
        accessor: 'location',
        Cell: ({ cell }: { cell: any }) => <RenderLocation value={cell.row.original.location} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>EMISSIONS (tCO2e)</p></div>,
        accessor: 'emissions',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={cell.row.original.emissions} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>TOTAL EMPLOYEES</p></div>,
        accessor: 'totalEmployees',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={cell.row.original.totalEmployees} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>EMISSIONS/EMPLOYEE (tCO2e)</p></div>,
        accessor: 'emissionsPerEmployee',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={cell.row.original.emissionsPerEmployee} />
      },
    ],
    []
  )

  return (
    <div className='p-0 m-0'>
      <ReactTablePurple
        headerData={headers}
        tableData={listData}
        className='mt-6 custom-border'
        currentPageIndex={0}
        disableSort
        showPagination={false}
        pageSize={listData.length}
        message="No results found"
      />
    </div>
  )
}

export default LocationEmissionTable