import { DownloadIcon } from '../../../_metronic/assets/icons'
import ScopeOneEmissionTable from './components/scope-one-emission-table'
import { scopeOneCategoryData } from './docs-data'
import { downloadScopeOneReportCSV } from '../../utils/helpers'

const ScopeOnePage = () => (
  <div className="blank-page-view pt-6 pb-16 px-6">
    <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
      <h1 className='m-0' style={{fontWeight: 600}}>SCOPE 1</h1>
      <button
        type='button'
        className='custom-primary-delete-button m-0 d-flex align-items-center'
        style={{padding: '8px 30px', fontWeight: 600}}
        onClick={()=> downloadScopeOneReportCSV(scopeOneCategoryData)}
        >
        <p className='mb-0 mx-2'>DOWNLOAD REPORT</p> <DownloadIcon height={18} width={18} />
      </button>
    </div>

    <div className='mt-10'>
      <ScopeOneEmissionTable data={scopeOneCategoryData} />
    </div>  
  </div>
)

export default ScopeOnePage
