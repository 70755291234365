import { ManualYearCumulativeSurveyRequestType } from "../store/type";

export const validateSurveyQuestions = (formState: ManualYearCumulativeSurveyRequestType): { isValid: boolean, errorMessage: string } => {
  // Validate office details
  const isOfficeDetailsValid = formState.office_electricity_consumption_details.every((office: any) => 
    office.geo_id && 
    office.area_sq_ft && 
    office.no_of_employees &&
    office.avg_wfh_days_per_week_per_employee !== undefined &&
    office.is_igbc_certified !== undefined &&
    office.is_renewable_energy_sourced !== undefined
  );
  if (!isOfficeDetailsValid) {
    return {
      isValid: false,
      errorMessage: "Please complete office energy consumption details"
    };
  }

  // Validate employee commute details
  if (!formState.employee_commute_details.no_of_company_owned_vehicles ||
      !formState.employee_commute_details.avg_km_per_day_per_vehicle ||
      !formState.employee_commute_details.no_of_employee_using_pvt_vehicles ||
      !formState.employee_commute_details.avg_km_per_employee_per_day) {
    return {
      isValid: false,
      errorMessage: "Please complete employee office commute details"
    };
  }

  // Validate light traveler details
  if (!formState.light_traveler_details.no_of_employee_in_light_travels ||
      !formState.light_traveler_details.local_avg_round_trip_per_person_per_quarter ||
      !formState.light_traveler_details.medium_avg_round_trip_per_person_per_quarter ||
      !formState.light_traveler_details.long_avg_round_trip_per_person_per_quarter ||
      formState.light_traveler_details.local_travel_class === '' ||
      formState.light_traveler_details.medium_travel_class === '' ||
      formState.light_traveler_details.long_travel_class === '') {
    return {
      isValid: false,
      errorMessage: "Please complete professional air commute - light traveler details"
    };
  }

  // Validate heavy traveler details
  if (!formState.heavy_traveler_details.no_of_employee_in_heavy_travels ||
      !formState.heavy_traveler_details.local_avg_round_trip_per_person_per_quarter ||
      formState.heavy_traveler_details.local_travel_class === '' ||
      !formState.heavy_traveler_details.medium_avg_round_trip_per_person_per_quarter ||
      formState.heavy_traveler_details.medium_travel_class === '' ||
      !formState.heavy_traveler_details.long_avg_round_trip_per_person_per_quarter ||
      formState.heavy_traveler_details.long_travel_class === '') {
    return {
      isValid: false,
      errorMessage: "Please complete professional air commute - heavy traveler details"
    };
  }

  return {
    isValid: true,
    errorMessage: ""
  };
}