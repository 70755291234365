/* eslint-disable global-require */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesPath } from '../../../../routing/RoutesPath';

import { MarkerIcon } from '../../../../../_metronic/assets/icons';
import { ForestDetailIndividual } from '../../../our-forest/types';
import { forest1, forest2, forest3 } from '../../forest-details-data';

type Props = {
  forest: ForestDetailIndividual;
  forestName: string;
  companyName: string;
  forestNumber: string;
  location: string;
}

const FOREST_IMG_SOURCE = {
  ONE: require('../../../../../_metronic/assets/images/png-forest-1.png'),
  TWO: require('../../../../../_metronic/assets/images/png-forest-2.png'),
  THREE: require('../../../../../_metronic/assets/images/png-forest-1.png'),
}


const ForestCard: React.FC<Props> = (props) => {
  const { forest, forestName, companyName, forestNumber, location } = props;
  const navigate = useNavigate();

  let forestData = forest1;
  if (forestNumber === 'TWO') {
    forestData = forest2;
  } else if (forestNumber === 'THREE') {
    forestData = forest3;
  }

  return (
    <button type='button' className='forest-card col-4 mb-2 remove-button-styles' style={{textAlign: 'left'}} onClick={() => navigate(RoutesPath.FOREST.FOREST_DETAILS, {state: {forest, forestData}})}>
      <div className='d-flex flex-column gap-2'>
        <h3 className='m-0'>{forestName}</h3>
        <h6 className='m-0 mb-2' style={{ color: '#808080', fontSize: 12 }}>{companyName}</h6>
        <div className='d-flex flex-row gap-2 align-items-center'>
          <MarkerIcon color="black" height={24} style={{marginLeft: 0}} />
          <h6 className='m-0' style={{ fontSize: 13, width: '55%' }}>{location}</h6>
        </div>
      </div>
      <img src={FOREST_IMG_SOURCE[forestNumber as keyof typeof FOREST_IMG_SOURCE]} alt={forestName} height={70} width={70} />
    </button>
  )
}

export default ForestCard;