import { count } from "console"

export const contractsData = [
  {
    name: 'Contract1',
    url: 'https://www.google.com',
    date: '01-01-2022'
  },
  {
    name: 'Contract2',
    url: 'https://www.google.com',
    date: '02-02-2022'
  },
  {
    name: 'Contract3',
    url: 'https://www.google.com',
    date: '03-03-2022'
  },
  {
    name: 'Contract4',
    url: 'https://www.google.com',
    date: '04-04-2022'
  },
  {
    name: 'Contract5',
    url: 'https://www.google.com',
    date: '05-05-2022'
  },
  {
    name: 'Contract6',
    url: 'https://www.google.com',
    date: '06-06-2022'
  },
  {
    name: 'Contract7',
    url: 'https://www.google.com',
    date: '07-07-2022'
  }
]

export const farmerDetailsData = [
  {
    name: 'FarmerDetails1',
    url: 'https://www.google.com',
    date: '08-08-2022'
  },
  {
    name: 'FarmerDetails2',
    url: 'https://www.google.com',
    date: '09-09-2022'
  },
  {
    name: 'FarmerDetails3',
    url: 'https://www.google.com',
    date: '10-10-2022'
  },
  {
    name: 'FarmerDetails4',
    url: 'https://www.google.com',
    date: '11-11-2022'
  },
  {
    name: 'FarmerDetails5',
    url: 'https://www.google.com',
    date: '12-12-2022'
  }
]

export const donationsData = [
  {
    name: 'Donation1',
    url: 'https://www.google.com',
    date: '01-01-2023'
  },
  {
    name: 'Donation2',
    url: 'https://www.google.com',
    date: '02-02-2023'
  }
]

export const receiptsData = [
  {
    name: 'Receipt1',
    url: 'https://www.google.com',
    date: '03-03-2023'
  },
  {
    name: 'Receipt2',
    url: 'https://www.google.com',
    date: '04-04-2023'
  },
  {
    name: 'Receipt3',
    url: 'https://www.google.com',
    date: '05-05-2023'
  },
  {
    name: 'Receipt4',
    url: 'https://www.google.com',
    date: '06-06-2023'
  },
  {
    name: 'Receipt5',
    url: 'https://www.google.com',
    date: '07-07-2023'
  },
  {
    name: 'Receipt6',
    url: 'https://www.google.com',
    date: '08-08-2023'
  },
]

export const forestPlantationChartData = {
  series: [
    {name: 'Acres Purchased', data: [15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15]},
    {name: 'Acres Planted', data: [3.9, 4.2, 4.6, 5.5, 5.9, 6.2, 6.2, 7.35, 9.25, 9.75, 11]}
  ],
  categories: ['Jan 2024', 'Feb 2024', 'Mar 2024', 'Apr 2024', 'May 2024', 'Jun 2024', 'Jul 2024', 'Aug 2024', 'Sep 2024', 'Oct 2024', 'Nov 2024']
}

export const forestDonutContributionChartData = [
  {name: 'MALES', count: 110},
  {name: 'FEMALES', count: 10}
]

export const forestTotalForestAreaChartData = [
  {name: 'AREA', count: 120}
]

export const forestTotalTreesPlantedChartData = [
  {name: 'TREES PLANTED', count: 1000000}
]

export const forestTotalRecapturedChartData = [
  {name: 'RECAPTURED', count: 1000000}
]