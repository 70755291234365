import { useDispatch, useSelector } from "react-redux";
import { AccordionDivProps } from "../types";
import { RenderStatusPill } from "./render-status-pill";
import { InputNumber } from "../../../../ui/components/input-text";
import { setScopeSurveyFormField, setSectionStatus, FORM_FIELDS, SectionStatus } from "../../../../store/slices/forms/scopeSurveyForm";
import { RootState } from "../../../../store/store";

const AccordionDivTwo = (props: AccordionDivProps) => {
  const {data} = props;
  const dispatch = useDispatch();
  const formState = useSelector((state: RootState) => state.scopeSurveyForm);

  const handleFieldChange = (field: FORM_FIELDS, value: any) => {
    dispatch(setScopeSurveyFormField({field, data: value}));

    // Create updated state with new value
    const updatedState = {
      ...formState,
      [field]: value
    };

    // Check status after field update using updated state
    const fieldsToCheck = [
      updatedState.numberOfCompanyVehicles,
      updatedState.averageCommuteDistancePerDay,
      updatedState.numberOfEmployeesUsingPrivateVehicles,
      updatedState.averageCommuteDistancePerEmployee
    ];

    const isStarted = fieldsToCheck.some(fieldValue => fieldValue !== undefined);

    const isCompleted = fieldsToCheck.every(fieldValue => fieldValue !== undefined); 

    let status: SectionStatus = "not-started";
    if (isCompleted) {
      status = "completed";
    } else if (isStarted) {
      status = "in-progress";
    }

    dispatch(setSectionStatus({
      section: "employeeCommuteStatus",
      status
    }));
  };

  return (
    <div className="accordion bordered-grey" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
      <div className="accordion-item" style={{border: 'none'}}>
        <h2 className="accordion-header py-2" id='twoSection'>
          <button 
            className="accordion-button collapsed fs-3 bold bg-white" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target='#panel-twoSection' 
            aria-expanded="false" 
            aria-controls='panel-twoSection'
          >
            <p className='report-sec-header' style={{color: "black"}}>{data?.title}</p>
          </button>
          <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
            <RenderStatusPill status={data?.status} />
          </div>
        </h2>
        <div id='panel-twoSection' className="accordion-collapse collapse" aria-labelledby='twoSection'>
          <div className="accordion-body">
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Number of Company-owned Vehicles'
                  value={formState.numberOfCompanyVehicles}
                  onChange={(v) => handleFieldChange('numberOfCompanyVehicles', v)}
                  placeholder='Enter Number of Vehicles'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Average Commute Distance Per Day'
                  value={formState.averageCommuteDistancePerDay}
                  onChange={(v) => handleFieldChange('averageCommuteDistancePerDay', v)}
                  placeholder='Enter Average Distance'
                  unit='km'
                />
              </div>
              <div className='col-4'>
                <InputNumber
                  label='Number of Employees Using Private Vehicles'
                  value={formState.numberOfEmployeesUsingPrivateVehicles}
                  onChange={(v) => handleFieldChange('numberOfEmployeesUsingPrivateVehicles', v)}
                  placeholder='Enter Number of Employees'
                />
              </div>
            </div>
            <div className='row px-8 py-6'>
              <div className='col-4'>
                <InputNumber
                  label='Average Commute Distance/Employee/Day'
                  value={formState.averageCommuteDistancePerEmployee}
                  onChange={(v) => handleFieldChange('averageCommuteDistancePerEmployee', v)}
                  placeholder='Enter Average Distance'
                  unit='km'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionDivTwo