import React from 'react'

interface TableStyles {
  table: React.CSSProperties;
  headerCell: React.CSSProperties;
  headerCellFirst: React.CSSProperties;
  headerCellLast: React.CSSProperties;
  rowSpanCell: React.CSSProperties;
  standardCell: React.CSSProperties;
  totalCell: React.CSSProperties;
}

const tableStyles: TableStyles = {
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
    width: '100%',
    fontSize: '14px',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: '#B4A5D3',
    borderRadius: '8px',
  },
  headerCell: {
    backgroundColor: '#D7D3E4',
    padding: '16px 12px',
    textAlign: 'center',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: '#B4A5D3'
  },
  headerCellFirst: {
    borderRadius: '8px 0 0 0',
  },
  headerCellLast: {
    borderRadius: '0 8px 0 0',
  },
  rowSpanCell: {
    verticalAlign: 'middle',
    backgroundColor: '#FAFAFC',
    padding: '12px',
    textAlign: 'center',
    fontWeight: 500,
    color: 'black',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: '#B4A5D3',
    borderRadius: '0 0 0 8px',
  },
  standardCell: {
    padding: '18px 12px',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    fontWeight: 500,
    color: 'black',
    borderWidth: '0.5px',
    borderStyle: 'solid',
    borderColor: '#B4A5D3'
  },
  totalCell: {
    fontWeight: 'bold',
    borderRadius: '0 0 8px 0',
  }
}

const ScopeOneEmissionTable = ({data}: {data: any}) => (
  <table 
    className="table table-bordered" 
    style={tableStyles.table}
  >
    <thead>
      <tr>
        <th style={{...tableStyles.headerCell, ...tableStyles.headerCellFirst}}>Operational Boundary</th>
        <th style={tableStyles.headerCell}>Emissions Source</th>
        <th style={tableStyles.headerCell}>Emissions (TCO2e)</th>
        <th style={{...tableStyles.headerCell, ...tableStyles.headerCellLast}}>Total Emissions (TCO2e)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td rowSpan={3} style={tableStyles.rowSpanCell}>{data[0].operationalBoundary}</td>
        <td style={tableStyles.standardCell}>{data[0].data[0].emissionSource}</td>
        <td style={tableStyles.standardCell}>{data[0].data[0].emissions}</td>
        <td rowSpan={3} style={{...tableStyles.rowSpanCell, ...tableStyles.totalCell}}>{data[0].totalEmissions}</td>
      </tr>
      <tr>
        <td style={tableStyles.standardCell}>{data[0].data[1].emissionSource}</td>
        <td style={tableStyles.standardCell}>{data[0].data[1].emissions}</td>
      </tr>
      <tr>
        <td style={{...tableStyles.standardCell}}>{data[0].data[2].emissionSource}</td>
        <td style={tableStyles.standardCell}>{data[0].data[2].emissions}</td>
      </tr>
    </tbody>
  </table>
)

export default ScopeOneEmissionTable
